<template>
  <div>
    <v-card flat class="pa-3 mt-2">
      <v-card-text class="d-flex">
        <v-avatar rounded size="120" class="me-6">
          <v-img :src="cropped"></v-img>
        </v-avatar>
      </v-card-text>

      <v-card-text>
        <v-form ref="form" class="multi-col-validation mt-6">
          <v-row>
            <v-toolbar dense dark color="primary">
              <v-toolbar-title><h4 class="font-weight-light">PERSONAL INFOMATION</h4>
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-col md="4" cols="12"></v-col>
              <v-row>
                <v-col md="12" cols="12">
                  <v-text-field
                    v-model="name"
                    label="Name"
                    dense
                    outlined
                    :rules="rules.default_max_255_character_and_no_empty_rule"
                  ></v-text-field>
                </v-col>
                <v-col md="4" cols="12">
                  <v-text-field
                    v-model="id_no"
                    label="ID #"
                    dense
                    outlined
                    :rules="rules.default_max_45_character_and_no_empty_rule"
                  ></v-text-field>
                </v-col>
                <v-col md="4" cols="12">
                  <v-text-field
                    v-model="email"
                    label="Email Address"
                    dense
                    outlined
                    :rules="rules.email_rule"
                  ></v-text-field>
                </v-col>
                <v-col md="4" cols="12">
                  <v-select
                    v-model="position"
                    dense
                    outlined
                    label="Position"
                    :items="['CASHIER', 'MANAGER', 'ADMIN', 'ADMIN SPECIAL']"
                    :rules="rules.combobox_rule"
                  ></v-select>
                </v-col>
                <v-col md="4" cols="12" v-if="id===0">
                  <v-select
                    v-model="branch_info"
                    :items="branch_items"
                    item-text="branch_code"
                    item-value="id"
                    label="Branch"
                    dense
                    outlined
                    :rules="rules.combobox_rule"
                  ></v-select>
                </v-col>
              </v-row>
            </v-card-text>
            <!-- alert -->
            <v-col cols="12" v-show="alert">
              <v-alert color="warning" text class="mb-0">
                <div class="d-flex align-start">
                  <v-icon color="warning">
                    {{ icons.mdiAlertOutline }}
                  </v-icon>

                  <div class="ms-3">
                    <p class="text-base font-weight-medium mb-1">
                      {{ alert_message }}
                    </p>
                  </div>
                </div>
              </v-alert>
            </v-col>

            <v-col cols="12" v-if="id===0">
              <v-btn color="primary" class="me-3 mt-4" @click="save_caller" v-if="!saving"> Save
                changes
              </v-btn>
              <v-progress-circular :size="50" :width="5" color="primary" indeterminate
                                   v-else></v-progress-circular>
            </v-col>
            <v-col cols="12" v-else>
              <v-btn color="primary" class="me-3 mt-4" @click="update_info" v-if="!saving"> Update
                changes
              </v-btn>
              <v-progress-circular :size="50" :width="5" color="primary" indeterminate
                                   v-else></v-progress-circular>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-text class="mt-4">
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-uppercase">ID</th>
              <th class="text-center text-uppercase">Branch</th>
              <th class="text-center text-uppercase">ID #</th>
              <th class="text-center text-uppercase">NAME</th>
              <th class="text-center text-uppercase">Position</th>
              <th class="text-center text-uppercase">Status</th>
              <th class="text-center text-uppercase">Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in initialize_datas" :key="item.id">
              <td>{{ item.id }}</td>
              <td class="text-center">
                {{ item.branch.branch_code }}
              </td>
              <td class="text-center">
                {{ item.id_no}}
              </td>
              <td class="text-center">
                {{ item.name}}
              </td>
              <td class="text-center">
                {{ item.position}}
              </td>
              <td class="text-center">
                {{ item.status}}
              </td>
              <td class="text-center">
                <div>
                  <v-icon
                    v-if="!is_updating"
                    class="mr-2"
                    color="success"
                    @click="approved(initialize_datas[initialize_datas.map(function(x) {return x.id; }).indexOf(item.id)])"
                  >
                    {{icons.mdiPencilBoxOutline}}
                  </v-icon>
                  <v-progress-circular color="info" indeterminate
                                       v-else></v-progress-circular>
                </div>
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>

    <snack-bar-dialog :snackbar_flag="this.snackbar" :color="this.snackbar_color"
                      :snackbar_text="this.snackbar_text"/>
  </div>
</template>

<script>
  import {
    mdiAlertOutline,
    mdiCloudUploadOutline,
    mdiAccountSearchOutline,
    mdiPencilBoxOutline
  } from '@mdi/js'
  import {mapGetters, mapActions} from 'vuex'
  import moment from 'moment'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      cropped: require('@/assets/images/avatars/2.png'),

      branch_info: '',
      branch_items: [],

      saving: false,
      alert: false,
      alert_message: '',

      b_id: 0,
      id: 0,
      id_no: '',
      email: '',
      name: '',
      position: '',

      initialize_datas: [],
      is_updating: false,
    }
  }

  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        show: false,
        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
          mdiAccountSearchOutline,
          mdiPencilBoxOutline,
        },
      }
    },
    mounted() {
      this.initialize_data()
    },
    data() {
      return initialState()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
      ...mapGetters('authentication', ['branch', 'branch_id', 'year', 'month_start', 'month_end', 'month_of', 'user_id']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('users', ['register_user', 'get_all_users', 'update_credentials']),
      ...mapActions('branch_information', ['get_branches_active']),
      initialize_data() {
        this.get_all_users()
          .then(response => {
            this.initialize_datas = response.data
          })
          .catch(error => {
            console.log(error)
          })
        this.get_branches_active()
          .then(response => {
            this.branch_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },

      reset() {
        Object.assign(this.$data, initialState())
        this.initialize_data()
      },
      save_caller() {
        this.saving = true
        this.alert = false
        if (this.$refs.form.validate()) {
          const data = new FormData()
          data.append('branch_id', this.branch_items[this.branch_items.map(function (x) {
            return x.id;
          }).indexOf(this.branch_info)].id)
          data.append(
            'name',
            this.name.toUpperCase(),
          )
          data.append('id_no', this.id_no)
          data.append('email', this.email)
          data.append('password', 'GLEXPRESS2022')
          data.append('position', this.position)
          this.register_user(data)
            .then(response => {
              var color = 'success'
              if (response.status === 201) {
                color = 'error'
              } else {
                this.reset()
              }
              this.change_snackbar({
                show: true,
                color: color,
                text: response.data,
              })
            })
            .catch(error => {
              console.log(error)
              this.alert = true
              this.alert_message = error.response.data.message
              this.saving = false
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving = false
        }
      },
      update_info() {
        this.saving = true
        this.alert = false
        if (this.$refs.form.validate()) {
          const data = new FormData()
          data.append('branch_id', this.b_id)
          data.append(
            'name',
            this.name.toUpperCase(),
          )
          data.append('id', this.id)
          data.append('id_no', this.id_no)
          data.append('email', this.email)
          data.append('password', 'GMCC2022')
          data.append('position', this.position)
          this.update_credentials(data)
            .then(response => {
              var color = 'success'
              if (response.status === 201) {
                color = 'error'
              } else {
                this.reset()
              }
              this.change_snackbar({
                show: true,
                color: color,
                text: response.data,
              })
            })
            .catch(error => {
              console.log(error)
              this.alert = true
              this.alert_message = error.response.data.message
              this.saving = false
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving = false
        }
      },
      approved(data) {
        this.b_id = data.branch.id
        this.id = data.id
        this.id_no = data.id_no
        this.email = data.email
        this.name = data.name
        this.position = data.position
      }
    },
  }
</script>
